import { SecurityScanFilled, SettingFilled } from "@ant-design/icons";
import { Menu, Typography } from "@thepiquelab/archus-components-web";
import { useRequest } from "ahooks";
import { Col, Modal, Row, Space, Spin, message } from "antd";
import React, { useContext, useState } from "react";
import { useAuth0 } from "../Auth0Wrapper";
import { requestChangePassword, updateUser } from "../api/Users/index.api";
import AvatarUpload from "../components/AvatarUpload";
import AdminLink from "../components/CommonLink/AdminLink";
import { UploadFilePath } from "../generated/graphql";
import { CurrentAdminContext } from "./UserContent";

const { Item } = Menu;

interface MenuBarDropdownProps {
  onDropdownVisible?: (visible: boolean) => void;
}

const MenuBarDropdown: React.FC<MenuBarDropdownProps> = (props) => {
  const { onDropdownVisible } = props;
  const { user } = useContext(CurrentAdminContext);

  const { run } = useRequest(updateUser, {
    manual: true,
    onError: (error) => {
      // message.error(error.message);
    },
  });

  const auth0 = useAuth0();
  const [isShowUserSetting, setIsShowUserSetting] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const handleChangeModalVisible = (visible: boolean): void => {
    if (visible && onDropdownVisible) {
      onDropdownVisible(false);
    }

    setIsShowUserSetting(visible);
  };

  const handleUpdateAvatar = async (url: string): Promise<void> => {
    if (!url) return;
    run({ id: user.adminId, avatarUrl: url });
  };

  const resetPassword = async (): Promise<void> => {
    setLoading(true);
    try {
      await requestChangePassword(user.adminId);
      message.info(
        "A link to reset password has been sent to your registered Email.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Menu className="setting-menu shadow bg-white rounded" selectable={false}>
        <Item
          key="/settings/user"
          onClick={() => handleChangeModalVisible(true)}
        >
          <div className="flex items-center gap-2">
            <SettingFilled />
            <div className="flex items-center">User Settings</div>
          </div>
        </Item>
        <Item
          key="/logout"
          // @ts-ignore
          onClick={() => auth0.logout({ returnTo: window.location.origin })}
        >
          <div className="flex items-center gap-2">
            <SecurityScanFilled />
            <div className="flex items-center">Log Out</div>
          </div>
        </Item>
      </Menu>
      <Modal
        open={isShowUserSetting}
        onCancel={() => handleChangeModalVisible(false)}
        title="Profile Settings"
        width="520px"
        footer={null}
      >
        <Spin spinning={loading}>
          <Row className="w-full" wrap={false}>
            <Col flex="48px">
              <AvatarUpload
                size={48}
                avatarUrl={user?.avatar}
                path={UploadFilePath.ImagesUser}
                onUploaded={(url) => {
                  handleUpdateAvatar(url);
                }}
              />
            </Col>
            <Col flex="auto" className="ml-6">
              <Row wrap={false} className="w-full" gutter={[16, 0]}>
                <Col span={10}>
                  <Space direction="vertical" size={4}>
                    <Typography.Heading level={4} data-testid="fullNameLabel">
                      Full Name
                    </Typography.Heading>
                    <AdminLink id={user?.adminId} data-testid="fullName">
                      {user?.fullName || ""}
                    </AdminLink>
                  </Space>
                </Col>
                <Col span={14}>
                  <Space direction="vertical" size={4}>
                    <Typography.Heading level={4}>
                      Department
                    </Typography.Heading>
                    <Typography.Text size="middle">
                      {user?.department || ""}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row wrap={false} className="w-full my-6" gutter={[16, 0]}>
                <Col span={10}>
                  <Space direction="vertical" size={4}>
                    <Typography.Heading level={4}>
                      Contact No.
                    </Typography.Heading>
                    <Typography.Text size="middle">
                      {user?.mobile || ""}
                    </Typography.Text>
                  </Space>
                </Col>
                <Col span={14}>
                  <Space direction="vertical" size={4}>
                    <Typography.Heading level={4}>Email</Typography.Heading>
                    <Typography.Text size="middle">
                      {user?.email || ""}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>

              <span
                className="font_semibold text-primary-blue cursor-pointer"
                onClick={resetPassword}
              >
                Reset Password
              </span>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default MenuBarDropdown;
