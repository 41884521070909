import axios, {
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import qs from "qs";

interface InitProps {
  request: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
  response: (config: AxiosResponse) => AxiosResponse;
  responseError?: (config: any) => any;
}

const isArrayOfTypeString = (arr: Array<any>) => {
  return Array.isArray(arr) && arr.every((item) => typeof item === "string");
};

class Axios {
  public static instance: AxiosInstance;

  public static init(config: CreateAxiosDefaults & InitProps) {
    const { request, response, responseError, ...res } = config;
    const instance = axios.create({ ...res });

    instance.interceptors.request.use(
      async (config) => {
        const token = sessionStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return request(config);
      },
      (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(
      (config) => {
        return response(config);
      },
      (error) => {
        responseError?.(error);
      },
    );

    if (!this.instance) {
      this.instance = instance;
    }
  }

  public static get<T, U>(url: string, params?: T): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .get(url, {
          params,
          paramsSerializer: (query) => {
            const result: Record<string, any> = {};
            for (const key in query) {
              if (isArrayOfTypeString(query[key])) {
                result[key] = query[key].join(",");
              } else {
                result[key] = query[key];
              }
            }
            return qs.stringify(result);
          },
        })
        .then((response) => resolve(response?.data))
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static post<T, U>(url: string, data: T): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .post(url, data)
        .then((response) => resolve(response?.data))
        .catch((err) => reject(err));
    });
  }

  public static put<T, U>(url: string, data?: T): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .put(url, data)
        .then((response) => resolve(response?.data))
        .catch((err) => reject(err));
    });
  }

  public static setHeaderOptions(potions: RawAxiosRequestHeaders): void {
    if (!this.instance) {
      throw new Error("Please create an axios instance first!");
    }

    this.instance.defaults.headers.options = {
      ...this.instance.defaults.headers.options,
      ...potions,
    };
  }

  public static setAuthorization(authorization: string): void {
    if (!this.instance) {
      throw new Error("Please create an axios instance first!");
    }

    if (!authorization) {
      throw new Error("No token");
    }

    this.instance.defaults.headers.Authorization = `Bearer ${authorization}`;
  }
}

export default Axios;
