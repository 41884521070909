export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_API_AUDIENCE_CORE_SERVICE,
  scope: process.env.REACT_APP_AUTH0_SCOPES,
  organization: process.env.REACT_APP_AUTH0_ORGANIZATION,
  redirect_uri: window.location.origin ?? "http://localhost:3000",

  useRefreshTokens: true,
};

export const IsPermissionDebugMode =
  process.env.REACT_APP_PERMISSION_DEBUG_MODE === "true";

export const AppConfig = {
  BackendBaseUrl:
    process.env.REACT_APP_ARCHUS_CORE_SERVICE_ENDPOINT ||
    "http://localhost:7001",
  UserServiceUrl:
    process.env.REACT_APP_ARCHUS_USER_SERVICE_ENDPOINT ||
    "http://localhost:3007/api",
  GraphqlEndpoint: `${process.env.REACT_APP_ARCHUS_CORE_SERVICE_ENDPOINT}/${process.env.REACT_APP_ARCHUS_GRAPHQL_PATH}`,
  SubscriptionsEndpoint:
    process.env.REACT_APP_ARCHUS_SUBSCRIPTION_ENDPOINT ||
    "ws://localhost:7001/subscriptions",
  redirectPathname:
    process.env.REACT_APP_REDIRECT_PATH || window.location.pathname,
};
