import createAuth0Client, {
  Auth0ClientOptions,
  AuthenticationError,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { Skeleton, message } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";

import { replace } from "lodash";
import actions from "./QianKunActions";
import { getCurrentUser, syncRole } from "./api/Users/index.api";
import { AppConfig, Auth0Config } from "./config";
import { Axios } from "./config/axios";

export interface ContextValue {
  isAuthenticated?: boolean;
  userInOrganization?: boolean;
  user?: any;
  dbUser?: any;
  logout(options?: LogoutOptions): void;
  getToken: () => Promise<string>;
}

interface Auth0ProviderProps {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
  clientOptions: Auth0ClientOptions;
}

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<ContextValue>(
  {} as ContextValue,
);
export const useAuth0 = (): ContextValue => useContext(Auth0Context);

export const Auth0Provider: FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  clientOptions,
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [userInOrganization, setUserInOrganization] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [dbUser, setDbUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();

  useEffect(() => {
    // @ts-ignore
    if (window.__POWERED_BY_QIANKUN__) {
      actions.onGlobalStateChange((state: any) => {
        const token = replace(state.token, "Bearer ", "");
        Axios.setAuthorization(token);
      }, true);
      return;
    }
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client(clientOptions);
      // @ts-ignore
      setAuth0(auth0FromHook);
      if (
        (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) ||
        window.location.search.includes("error=")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (error) {
          if (error instanceof AuthenticationError) {
            const errorMessage =
              error.message === "user is blocked"
                ? `Failed login: Your account has been deactivated. `
                : error.message;
            message.error(errorMessage, 5, () =>
              auth0FromHook.logout({ returnTo: window.location.origin }),
            );
            return;
          }
        }
      }

      const isAuthorized = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthorized);

      if (isAuthorized) {
        const accessToken = await auth0FromHook.getTokenSilently();
        sessionStorage.setItem("token", accessToken);

        Axios.setAuthorization(accessToken);

        const userClaims = await auth0FromHook.getIdTokenClaims();
        setUser(userClaims);

        try {
          const user = await getCurrentUser();
          if (!user?._id) {
            message.error("User not found.", 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
          }
          await syncRole();
          setDbUser(user);
          setUserInOrganization(true);
        } catch (error) {
          //@ts-ignore
          if (error.response?.status === 401) {
            //@ts-ignore
            message.error(error.response?.data?.message, 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
            //@ts-ignore
          } else if (error.response?.status === 403) {
            //@ts-ignore
            message.error(error.response?.data?.message, 5, () => {
              auth0FromHook.logout({
                returnTo: Auth0Config.redirect_uri,
              });
            });
          } else {
            //@ts-ignore
            message.error(error.response?.data?.message || error.message);
          }
        }
      } else {
        await auth0FromHook.loginWithRedirect({
          appState: {
            targetUrl: AppConfig.redirectPathname,
          },
        });
      }
    };
    if (Object.values(clientOptions || {})?.length) {
      initAuth0();
    }
  }, [clientOptions]);

  const getToken = async (): Promise<string> => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      return null;
    }

    const decoded: { exp: number } = jwtDecode(token);

    if (moment(decoded.exp * 1000).isBefore()) {
      try {
        const newToken = await auth0Client.getTokenSilently();
        sessionStorage.setItem("token", newToken);
        token = newToken;
      } catch (error) {
        message.error("Refresh token failed, please refresh page.");
        window.location.reload();
      }
    }

    Axios.setAuthorization(token);

    return token;
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userInOrganization,
        dbUser,
        logout: (options?: LogoutOptions) => {
          sessionStorage.clear();
          auth0Client.logout(options);
        },
        getToken,
      }}
    >
      {
        // @ts-ignore
        window.__POWERED_BY_QIANKUN__ ? (
          children
        ) : (
          <>{!dbUser ? <Skeleton /> : children}</>
        )
      }
    </Auth0Context.Provider>
  );
};
