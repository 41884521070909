import { ConfigProvider, message, Skeleton } from "antd";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Auth0Provider } from "./Auth0Wrapper";
import { AppConfig, Auth0Config } from "./config";
import actions from "./QianKunActions";

import { AxiosError } from "axios";
import { Suspense } from "react";
import { Axios } from "./config/axios";
import "./public-path";
import reportWebVitals from "./reportWebVitals";
import history from "./utils/history";

import "./index.less";

let root: ReactDOM.Root;

Axios.init({
  baseURL: AppConfig.UserServiceUrl,
  request: (config) => config,
  response: (config) => config?.data,
  responseError: (err: AxiosError) => {
    message.error(
      (err?.response?.data as any)?.message ||
        (err?.response?.data as any)?.error?.message ||
        err.message,
    );
    throw err;
  },
});

function render(props: any) {
  root = ReactDOM.createRoot(
    props?.container
      ? props?.container.querySelector("#archus-administration-root")
      : document.querySelector("#archus-administration-root"),
  );

  const onRedirectCallback = (appState?: any): void => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  };

  ConfigProvider.config({
    prefixCls: "ce-ant",
  });

  message.config({
    getContainer: () =>
      document.getElementById("archus-administration-root") as HTMLElement,
    prefixCls: "ce-ant-message",
  });

  root.render(
    <Suspense fallback={<Skeleton />}>
      <BrowserRouter
        basename={
          // @ts-ignore
          window.__POWERED_BY_QIANKUN__ ? "/system-user" : "/"
        }
      >
        <ConfigProvider
          prefixCls="ce-ant"
          getTargetContainer={() =>
            document.getElementById("archus-administration-root") as HTMLElement
          }
          getPopupContainer={() =>
            document.getElementById("archus-administration-root") as HTMLElement
          }
        >
          <Auth0Provider
            clientOptions={Auth0Config}
            onRedirectCallback={onRedirectCallback}
          >
            <App />
          </Auth0Provider>
        </ConfigProvider>
      </BrowserRouter>
    </Suspense>,
  );
}

//@ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {}

export async function mount(props: any) {
  actions.setActions(props);
  render(props);
}

export async function unmount(props: any) {
  root.unmount();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
