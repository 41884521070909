import { ApolloProvider } from "@apollo/client";
import { Skeleton } from "antd";
import jwtDecode from "jwt-decode";
import { isEmpty, replace } from "lodash";
import moment from "moment";
import { lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import createApolloClient from "./ApolloClient";
import { useAuth0 } from "./Auth0Wrapper";
import BaseLayout from "./Layout/BaseLayout";
import SpecialLayout from "./Layout/SpecialLayout";
import actions from "./QianKunActions";
import { OAuthPermission } from "./config/permission";
import { useAccessControl } from "./hooks/useAccessControl";

const InsufficientCredentialsPage = lazy(
  () => import("./pages/Errors/InsufficientCredentialsPage"),
);

function App() {
  const { logout } = useAuth0();

  const [userToken, setUserToken] = useState<string>();

  const { hasAllPermissions } = useAccessControl();

  useEffect(() => {
    handleToken();
  }, []);

  const handleToken = async () => {
    //@ts-ignore
    if (!window.__POWERED_BY_QIANKUN__) {
      const token = sessionStorage.getItem("token");
      if (isEmpty(token)) {
        logout();
        return;
      }

      const decoded: { exp: number } = jwtDecode(token);
      if (moment(decoded.exp * 1000).isBefore()) {
        logout();
        return;
      }

      setUserToken(token);
      return;
    }

    // qiankun use token
    actions.onGlobalStateChange((state: any) => {
      const token = replace(state.token, "Bearer ", "");
      setUserToken(token);
    }, true);
  };

  if (!userToken) {
    return <Skeleton />;
  }

  return (
    <ApolloProvider client={createApolloClient(userToken)}>
      {
        // @ts-ignore
        window.__POWERED_BY_QIANKUN__ ? (
          <SpecialLayout />
        ) : (
          <>
            {hasAllPermissions([
              OAuthPermission.ARCHUS_ADMINISTRATION_ACCESS,
            ]) ? (
              <BaseLayout />
            ) : (
              <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
                <Route
                  index
                  path="/error"
                  element={<InsufficientCredentialsPage />}
                />
              </Routes>
            )}
          </>
        )
      }
    </ApolloProvider>
  );
}

export default App;
