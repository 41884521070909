import { Axios } from "../../config/axios";
import { PageResult } from "../common.type";
import {
  CreateSystemUserCommand,
  ExistsEmailQuery,
  ExistsMobileQuery,
  SystemUser,
  SystemUserQuery,
  UpdateSystemUserCommand,
} from "./index.type";

export const getUser = async (id: string): Promise<SystemUser> =>
  await Axios.get(`/v1/system-users/${id}`);

export const getCurrentUser = async (): Promise<SystemUser> =>
  await Axios.get("/v1/system-users/me");

export const getUserWithIds = async (ids: string[]): Promise<SystemUser[]> =>
  await Axios.get("/v1/system-users/ids", { ids });

export const getUserList = async (
  query: SystemUserQuery,
): Promise<PageResult<SystemUser>> =>
  await Axios.get("/v1/system-users", query);

export const existsByEmail = async (
  query: ExistsEmailQuery,
): Promise<boolean> =>
  await Axios.get("/v1/system-users/exists-by-email", query);

export const existsByMobile = async (
  query: ExistsMobileQuery,
): Promise<boolean> =>
  await Axios.get("/v1/system-users/exists-by-mobile", query);

export const createUser = async (
  data: CreateSystemUserCommand,
): Promise<SystemUser> => await Axios.post("/v1/system-users", data);

export const updateUser = async (
  data: UpdateSystemUserCommand,
): Promise<SystemUser> => await Axios.put("/v1/system-users", data);

export const requestChangePassword = async (id: string): Promise<boolean> =>
  await Axios.put(`/v1/system-users/${id}/request-change-password`);

export const syncRole = async (): Promise<boolean> =>
  await Axios.put(`/v1/system-users/me/sync-role`);

export const deactivate = async (
  id: string,
  removeUserRoles: boolean,
): Promise<boolean> =>
  await Axios.put(
    `/v1/system-users/${id}/deactivate?removeUserRoles=${removeUserRoles}`,
  );
