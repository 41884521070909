import {
  ClusterOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { lazy } from "react";
import { useAccessControl } from "../hooks/useAccessControl";
import { OAuthPermission } from "./permission";

export interface RoutesProps {
  path: string;
  label: string;
  hide?: boolean;
  icon?: React.ForwardRefExoticComponent<any>;
  hideItems?: boolean;
  items?: Omit<RoutesProps, "items">[];
  element?: React.LazyExoticComponent<React.FC>;
}

export const ExcludeRouters = ["/error"];

const useRouter = () => {
  const { hasSomePermissions } = useAccessControl();

  const routes: RoutesProps[] = [
    {
      path: "/",
      label: "System Users",
      icon: UserOutlined,
      hide: !hasSomePermissions([OAuthPermission.SYSTEM_USER_VIEW]),
      hideItems: true,
      items: [
        {
          path: "user",
          label: "List",
          hide: true,
          element: lazy(() => import("../pages/Users/ListUsers")),
        },
        {
          path: `profile/:adminId`,
          label: "Create User",
          hide: true,
          element: lazy(() => import("../pages/Users/UserProfile/UserProfile")),
        },
        {
          path: "create",
          label: "Create User",
          hide: true,
          element: lazy(() => import("../pages/Users/CreateUser/CreateUser")),
        },
        {
          path: "edit/:adminId",
          label: "Edit User",
          hide: true,
          element: lazy(() => import("../pages/Users/EditUser/EditUser")),
        },
      ],
    },
    {
      path: "/role",
      label: "Roles",
      hide: !hasSomePermissions([OAuthPermission.ROLE_VIEW]),
      icon: ClusterOutlined,
      hideItems: true,
      items: [
        {
          path: "",
          label: "Role Details",
          hide: true,
          element: lazy(() => import("../pages/Roles/ListRoles")),
        },
        {
          path: `details/:id`,
          label: "Role Details",
          hide: true,
          element: lazy(() => import("../pages/Roles/RoleDetails/RoleDetails")),
        },
      ],
    },
    {
      path: "/permissions",
      label: "Permissions",
      icon: SafetyOutlined,
      hide: !hasSomePermissions([OAuthPermission.PERMISSION_VIEW]),
      element: lazy(() => import("../pages/Permissions/ListPermissions")),
    },
    {
      path: "/error",
      label: "Error",
      hide: true,
      element: lazy(
        () => import("../pages/Errors/InsufficientCredentialsPage"),
      ),
    },
  ];

  return { routes };
};

export default useRouter;
